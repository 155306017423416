import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/SEO'
import { Flex, Box } from 'reflexbox'
import { Wrap, Copy, Title } from '../components/page/components'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'

export default () => {
  return (
    <Wrap>
      <SEO title="Sign Up Complete" />
      <FocusHeader title="Sign Up" backUrl="/" />
      <FocusBody>
        <Title>You're Ready!</Title>
        <Flex flexWrap={['wrap']} width={`100%`}>
          <Box width={[1, 6 / 12]}>
            <Copy>
              <p>
                Woohoo. Your account has been setup and is ready to use. We’ve
                also sent a confirmation email.
              </p>
              <p>
                To get started please <Link to="/sign-in">sign-in here</Link>.
              </p>
            </Copy>
          </Box>
        </Flex>
      </FocusBody>
    </Wrap>
  )
}
